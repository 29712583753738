import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Inject,
    ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PasswordDialogComponent } from '../../../main/owner/profile/profile.component';
import { ProfileService } from '../../../main/owner/profile/profile.service';
import { AuthService } from 'app/main/auth/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityService } from '../../../main/security/security.service';

import { Observable, interval } from 'rxjs';
import { map } from "rxjs/operators";

import { Moment } from 'moment'; // using @types/moment
import * as moment from 'moment';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { OwnerService } from '../../../main/owner/owner.service';
import { BuildingService } from '../../../main/building/building.service';

@Pipe({ name: 'safeHtml' })
export class SafeHtml {
    constructor(private sanitizer: DomSanitizer) {}

    transform(html) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    }
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    newEntry: boolean;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    name = 'Loading...';
    photo: any = null;
    gates = [];
    currentGate;
    showGates = false;
    showDeskAttendants = false;
    wsInicialized = false;
    wsClient: any;
    darkTheme = false;
    lastEntry: any;
    currentDate: string;
    pageLoaded: Moment;
    currentTime: Observable<string>;
    eventInterval: any;
    eventSubscription: any;
    lastEntryPhoto = 'assets/images/backgrounds/image_placeholder.png';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        public securityService: SecurityService,
        public dialog: MatDialog,
        public profileService: ProfileService,
        public snackBar: MatSnackBar,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'es',
                title: 'Español',
                flag: 'es'
            }
        ];

        this.profileService.languages = [...this.languages];



        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log('do');
        // Subscribe to the config changes
        this.currentDate = moment().format('L');
        this.currentTime = interval(1000).pipe( // why you need 1s interval with HH:mm time format simply update it every minute not every second.
            map(() => {
              this.pageLoaded = moment(new Date()); // you need the value of now not the value of the initialized time.
              return this.pageLoaded.format('LTS');
            })
          );

        if (this.router.url.includes('security')) {
            this.showGates = this.router.url.includes('security');
            this.getGates();
        }
        this.router.events.subscribe((route: any) => {
            if (route instanceof NavigationEnd) {
                // console.log('?', route);
                // console.log(this.gates);
                if (
                    route.url.includes('security') &&
                    this.showGates === false
                ) {
                    if (this.gates.length < 1) {
                        this.getGates();
                    }
                    this.showGates = true;
                } else if (this.showGates === true && !route.url.includes('security')) {
                    this.showGates = false;
                }
            }
        });
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
                this.name = settings.user.name + ' ' + settings.user.lastname;
                this.photo = settings.user.photo;
                this.darkTheme = settings.colorTheme === 'theme-blue-gray-dark';
                
            });

        // Set the selected language from default languages
        // this.selectedLanguage = _.find(this.languages, {
        //     id: this._translateService.currentLang
        // });
        this.selectedLanguage = _.find(this.languages, {
            id: this.profileService.language
        });
        const logout = this._fuseNavigationService.getNavigationItem('logout');
        if (logout === false) {
            const customFunctionNavItem = {
                'id'      : 'logout',
                'title'   : this._translateService.instant('NAV.LOGOUT.TITLE'),
                'type'    : 'item',
                'icon'    : 'exit_to_app',
                'function': () => {
                    this.logout();
                }
            };

            this._fuseNavigationService.addNavigationItem(
                customFunctionNavItem,
                'account'
            );
        }

        this.profileService.subscribe(data => {
            this.setLanguage(_.find(this.languages, {
                id: this.profileService.language
            }));
        });

        this.router.events.subscribe((route: any) => {
            if (route instanceof NavigationEnd) {
                if (route.url.includes('security')) {
                    this.getGates() // Trigger change detection
                }
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.eventInterval) {
            clearInterval(this.eventInterval);
        }
        // Unsubscribe from all subscriptions
        if (this.eventSubscription != undefined) {
            this.eventSubscription.unsubscribe();
        }
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    gateChange($event): void {
        if (this.securityService.isCheckedIn) {
          this.securityService.checkOut().subscribe(() => {
            this.securityService.isCheckedIn = false;
            this.snackBar.open(this._translateService.instant('NAV.GATE_CHANGED_CHECKOUT'), 'OK', {
                duration: 10000,
                panelClass: ['snack-success'],
            });
          });
        }

        this.securityService.selectedBuilding = $event.value.building;
        this.securityService.selectedBuildingSettings = $event.value.buildingSettings;
        this.securityService.selectedGate = $event.value.value;
        this.securityService.selectedGateCameras = {
            camera1: $event.value.camera1,
            camera2: $event.value.camera2
        };
        this.securityService.hasScanner = $event.value.scanner;
        this.securityService.selectedGateDoor = $event.value.principaldoor;
        this.securityService.selectGate($event.value);

        const filter = {
            building: this.securityService.selectedBuilding,
            dateFrom: moment(),
            dateTo: moment(),
            unit: '',
            status: 'TODAY',
            type: 'X'
        };

        this.getEventCounter(filter);

        if (this.securityService.selectedGateObject === 2 && this.securityService.selectedGateObject === null) {
            this._fuseNavigationService.updateNavigationItem('doors', {
                hidden: true
            })
            this._fuseNavigationService.updateNavigationItem('security_monitor', {
                hidden: true
            })
        } else {
            this._fuseNavigationService.updateNavigationItem('doors', {
                hidden: false
            })
            this._fuseNavigationService.updateNavigationItem('security_monitor', {
                hidden: false
            })
        }

        if (this.eventInterval) {
            clearInterval(this.eventInterval);
        }
        this.eventInterval = setInterval(() => {
            this.getEventCounter(filter);
        }, 30000);

        this.setupWebSocketClient($event.value.text, $event.value.building, $event.value.value);
    }

    changeTheme(): void {
        this.darkTheme = !this.darkTheme;
        const config = {... this._fuseConfigService.config};
        config.colorTheme = this.darkTheme ? 'theme-blue-gray-dark' : 'theme-default';
        this._fuseConfigService.setConfig(config);
        this._fuseConfigService.updateUserTheme(this.darkTheme).subscribe(data => {});
    }

    getEventCounter(filter): void {
        if (this.gates.length > 0) {
            if (this.eventSubscription != undefined) {
                this.eventSubscription.unsubscribe();
            }
            this.eventSubscription = this.securityService.getEventCounter(filter, '').subscribe(res => {
                this._fuseNavigationService.updateNavigationItem('events', {
                    badge: {
                        title: res.invitations
                    }
                });
            }); 
        }
    }
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        // console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Save the selected languge locally
        localStorage.setItem('language', lang.id);

        this._fuseNavigationService.updateNavigationItem('logout', {
            'id'      : 'logout',
            'title'   : this._translateService.instant('NAV.LOGOUT.TITLE'),
            'type'    : 'item',
            'icon'    : 'exit_to_app',
            'function': () => {
                this.logout();
            }
        });
    }

    logout(): void {
        const dialog = this.dialog.open(LogoutDialogComponent, {
            data: {}
        });
    }

    setupWebSocketClient(building: string, buildingId: string, gate: string): void {
        this.securityService.getLastAcceesEvent(buildingId, gate).subscribe(dataEntry => {
            const entry = {
                door_name: dataEntry.lastEntry.door_name || '',
                user_name: dataEntry.lastEntry.full_name || '',
                car: dataEntry.lastEntry.car_plate ? `${dataEntry.lastEntry.car_plate} - ${dataEntry.lastEntry.car_model} ` : '',
                building: dataEntry.lastEntry.building_gate || '',
                card: dataEntry.lastEntry.card_code || '',
                facility_code: dataEntry.lastEntry.facility_code || '',
                description: dataEntry.lastEntry.description || '',
                date: moment(dataEntry.lastEntry.date_created).format('YYYY-MM-DD h:mm:ss a'),
                event: dataEntry.lastEntry.event,
                port: dataEntry.lastEntry.port
            };
            
            this.lastEntry = entry;
            
            const value = localStorage.getItem("toolbar") ;
            
            if (value !=="0"  && value !==null ) {
                return;                
            }
        
            this.wsClient = this.securityService.setupMonitorLiveWS();
      
            if (this.wsInicialized) {
                return;
            }
  
            localStorage.setItem("toolbar","1");
    
            const sub = this.wsClient.subscribe(`/notification/${(gate + buildingId).replace(/\-/g, '')}`, async (data: any) => {
                
                this.newEntry = false;
                setTimeout (() => {
                    this.newEntry = true;
                }, 500);

                const entrySub = {
                    date: moment().format('YYYY-MM-DD h:mm:ss a'),
                    event: data.event,
                    port: data.port,
                    description: data.description || '',
                    door_name: data.door_name || '',
                    user_name: data.user_name || '',
                    card: data.card || '',
                    facility_code: data.facility_code || '',
                    car_plate: data.car_plate || '',
                    car_model: data.car_model || '',
                    building: data.building_gate || '',
                    car: data.car_plate ? `${data.car_plate} - ${data.car_model} ` : '',
                };
                this.lastEntry = entrySub;
                
                this.securityService.monitorNext(entrySub);

                // Get last entry photo
                const photoEvent = await this.securityService
                    .getPhotoEvent(this.lastEntry.port, this.lastEntry.event)
                    .toPromise();
                    
                this.lastEntryPhoto = photoEvent.photo || 'assets/images/avatars/avatar-wide.png';
            });

            sub.callback(function() {
                this.wsInicialized = true;
            });
            sub.errback(function(error) {
                console.log("Error sub WsClientToolbar:");
                console.log(error);
            });

            if (this.lastEntry) {
                // Get last entry photo
                this.securityService.getPhotoEvent(this.lastEntry.port, this.lastEntry.event).subscribe(data => {
                    this.lastEntryPhoto = data.photo || 'assets/images/backgrounds/image_placeholder.png';
                }, err => {
                });
            }
        },
        err => {
            console.log("Error WsClientToolbar:");
            console.log(err);
        }
        );
    }

    getGates(): void {
        this.securityService.getGates().subscribe(
            data => {
                const gates = data.gates.map(gate => {
                    const json = {
                        value: gate.gate,
                        building: gate.building,
                        text: `${gate.gatename} (${gate.buildingname})`,
                        gatename: gate.gatename,
                        buildingname: gate.buildingname,
                        principaldoor: gate.principaldoor,
                        camera1: {
                            camera1name: gate.camera1name,
                            camera1id: gate.camera1id
                            // camera1channel: gate.camera1channel,
                            // camera1port: gate.camera1port,
                            // camera1gtcport: gate.camera1gtcport
                        },
                        camera2: {
                            camera2name: gate.camera2name,
                            camera2id: gate.camera2id
                            // camera2channel: gate.camera2channel,
                            // camera2port: gate.camera2port,
                            // camera2gtcport: gate.camera2gtcport
                        },
                        scanner: gate.scanner,
                        buildingSettings: {
                            residentAddVehicles: gate.residentaddvehicles,
                            residentAddPets: gate.residentaddpets,
                            mandatoryPlate: gate.platemandatory,
                            callWithModem: gate.callwithmodem,
                            passesButtons: gate.passesbuttons,
                            saveShowLicenseNumber: gate.save_show_license_number,
                            securityAutocomplete: gate.security_autocomplete,
                            enableIncidentReport: gate.enable_incident_report,
                            showVirtualGuard: gate.virtual_guard_enabled && gate.virtual_guard_enabled_kiosks,
                        },
                        hasimage: gate.hasimage,
                        buildinglogo: gate.buildinglogo,
                        name: `${gate.gatename} (${gate.buildingname})`,
                        goutru_connector: gate.goutru_connector,
                        use_pin_in_units: gate.use_pin_in_units,
                        securityCheckedIn: gate.checked_in,
                        kiosks: gate.kiosks,
                        gate_type: gate.gate_type,
                        gate_guest_type: gate.gate_guest_type,
                    };
                    return json;
                });
                this.gates = [...gates];
                this.securityService.selectedBuilding =
                    this.gates.length > 0 ? this.gates[0].building : null;
                this.securityService.selectedBuildingSettings =
                    this.gates.length > 0 ? this.gates[0].buildingSettings : null;
                this.securityService.selectedGate =
                    this.gates.length > 0 ? this.gates[0].value : null;
                this.securityService.selectedGateCameras = {
                    camera1:
                        this.gates.length > 0 ? this.gates[0].camera1 : null,
                    camera2:
                        this.gates.length > 0 ? this.gates[0].camera2 : null
                };
                this.securityService.selectedGateDoor = this.gates.length > 0 ? this.gates[0].principaldoor : null;
                this.securityService.isCheckedIn = this.gates[0].securityCheckedIn;

                this.securityService.hasScanner = this.gates.length > 0 ? this.gates[0].scanner : false;

                this.securityService.selectedGateObject = this.gates[0];

                this.currentGate = this.gates[0];
                this.securityService.selectGate(this.gates[0]);
                if (this.gates[0].gate_type === 2 && this.gates[0].goutru_connector === null) {
                    this._fuseNavigationService.updateNavigationItem('doors', {
                        hidden: true
                    })
                    this._fuseNavigationService.updateNavigationItem('security_monitor', {
                        hidden: true
                    })
                }

                var filter = {
                    building: this.securityService.selectedBuilding,
                    dateFrom: moment(),
                    dateTo: moment(),
                    unit: '',
                    status: 'TODAY',
                    type: 'X'
                }

                this.getEventCounter(filter);
                
                if (this.eventInterval) {
                    clearInterval(this.eventInterval);
                }
                this.eventInterval = setInterval(() => {
                    this.getEventCounter(filter);
                }, 30000);

                this.setupWebSocketClient(this.gates[0].text, this.gates[0].building, this.gates[0].value);
            },
            err => {}
        );
    }

    retrivePhoto(): void {
        if (!this.lastEntry) {
            return;
        }

        this.securityService.getPhotoEvent(this.lastEntry.port, this.lastEntry.event).subscribe(data => {
            const img = data.photo;
            this.dialog.open(PhotoUserDialogComponent, {
                data: {
                    img,
                    camera: this.lastEntry.user_name,
                    card: this.lastEntry.card,
                    car: this.lastEntry.car,
                },
                minWidth: '700px',
            });
        }, err => {
        });
    }
}


@Component({
    selector: 'snapshot-dialog',
    templateUrl: './dialogs/snapshot-dialog.component.html',
})
export class PhotoUserDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}    
}

@Component({
    selector: 'logout-dialog',
    templateUrl: './logout-dialog/logout-dialog.component.html'
})
export class LogoutDialogComponent implements OnInit {
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<PasswordDialogComponent>,
        private _formBuilder: FormBuilder,
        public profileService: ProfileService,
        public fuseConfig: FuseConfigService,
        public authService: AuthService,
        private router: Router,
        private _fuseNavigationService: FuseNavigationService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ownerService: OwnerService,
        private buildingService: BuildingService,
        private securityService: SecurityService,
    ) {}

    ngOnInit(): void {}

    cancel(): void {
        this.dialogRef.close();
    }

    logout(): void {
        if (this.router.url.includes('security')){
            this.securityService.checkOut().subscribe(data => {
                this.securityService.isCheckedIn = false;
            });
        }

        this.authService.logout().subscribe(
            data => {
                localStorage.removeItem("videocall");
                localStorage.removeItem("toolbar");
                localStorage.removeItem('token');
                this.authService.isAuthenticated = false;
                sessionStorage.removeItem('token');
                this.router.navigate(['auth/login']);
                this.dialogRef.close();
                this.buildingService.building = null;
                this.ownerService.unit = null;
                this.securityService.gate = null;
                this._fuseNavigationService.updateNavigationItem('desk_attendant', {
                    hidden: true
                })
            },
            err => {
                localStorage.removeItem("videocall");
                localStorage.removeItem("toolbar");
                localStorage.removeItem('token');
                this.authService.isAuthenticated = false;
                sessionStorage.removeItem('token');
                this.router.navigate(['auth/login']);
                this.dialogRef.close();
                this.buildingService.building = null;
                this.ownerService.unit = null;
                this.securityService.gate = null;
            }
        );
    }
}